<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import DropZone from "@/components/widgets/dropZone";
import { ref } from "vue";
// import loader from "@/components/widgets/loader.vue";
import store from "@/state/store";
var state = store._modules.root._children.auth.state;
export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    DropZone,
    // loader,
  },
  setup() {
    let dropzoneFile = ref("");
    let url = ref(null);
    const drop = (e) => {
      dropzoneFile.value = e.dataTransfer.files[0];
      url = URL.createObjectURL(e.dataTransfer.files[0]);
      let img = document.createElement("img");
      img.src = url;
      if (document.querySelector(".img-prev").hasChildNodes()) {
        document.querySelector("#prev-img-el").remove();
      }
      img.id = "prev-img-el";
      document.querySelector(".img-prev").appendChild(img);
    };
    const selectedFile = () => {
      dropzoneFile.value = document.querySelector(".dropzoneFile").files[0];
      url = URL.createObjectURL(
        document.querySelector(".dropzoneFile").files[0]
      );
      let img = document.createElement("img");
      img.src = url;
      if (document.querySelector(".img-prev").hasChildNodes()) {
        document.querySelector("#prev-img-el").remove();
      }
      img.id = "prev-img-el";
      img.style.width = "150px";
      img.style.height = "150px";
      document.querySelector(".img-prev").appendChild(img);
      console.log(dropzoneFile.value);
      console.log(url);
    };

    return {
      url,
      dropzoneFile,
      drop,
      selectedFile,
    };
  },
  data() {
    return {
      products: [],
      loading: false,
      Cateogries: [],
      category: {},
      selectedCategory: {},
      page: 1,
      limit: 10,
      tot_pages: 0,
      searchCateogries: null,
      CateogriesSearchMode: false,
      searchModel: "",
      state: state,
    };
  },
  methods: {
    responseAlert(text, title, icon) {
      this.$swal.fire({
        title: `${title}`,
        text: ` ${text} `,
        icon: `${icon}`,
        toast: true,
        position: "top-start",
        showConfirmButton: false,
        timer: 2000,
      });
    },
    processEditCateogries(category) {
      this.selectedCategory = category;
    },
    addCateogries() {
      if (this.dropzoneFile) {
        this.category.image = this.dropzoneFile;
        this.http.do("cateogries", this.category).then((res) => {
          if (res.status) {
            this.dropzoneFile = {};
            if (document.querySelector(".img-prev").hasChildNodes()) {
              document.querySelector("#prev-img-el").remove();
            }
            this.category = {};
            if (this.$i18n.locale == "ar") {
              this.responseAlert("تم إضافة القسم بنجاح", " تم ", "success");
            } else {
              this.responseAlert(
                "Category added successfully",
                "done",
                "success"
              );
            }
          } else {
            if (this.$i18n.locale == "ar") {
              this.responseAlert("حدث خطأ اثناء إضافة القسم", "خطأ", "warning");
            } else {
              this.responseAlert("something went wrong", "error", "warning");
            }
          }
          this.get(this.page);
        });
        return;
      }
      this.http.post("cateogries", this.category).then((res) => {
        if (res.status) {
          this.category = {};
          if (this.$i18n.locale == "ar") {
            this.responseAlert("تم إضافة القسم بنجاح", " تم ", "success");
          } else {
            this.responseAlert(
              "Category added successfully",
              "done",
              "success"
            );
          }
        } else {
          if (this.$i18n.locale == "ar") {
            this.responseAlert("حدث خطأ اثناء إضافة القسم", "خطأ", "warning");
          } else {
            this.responseAlert("something went wrong", "error", "warning");
          }
        }
        this.get(this.page);
      });
      // console.log("**********####", this.state);
      // var data = {
      //   title: "popups.add",
      //   inputs: [
      //     { model: "name", type: "text", label: "cateogries.name" },
      //     // {
      //     //   model: "hide_from_vendor",
      //     //   type: "text",
      //     //   label: "hide_from_vendor",
      //     // },
      //     { model: "image", type: "file", label: "cateogries.image" },
      //     {
      //       model: "parent_id",
      //       type: "select",
      //       options: (() => {
      //         let cats = this.categories?.map((j) => ({
      //           id: j?.id,
      //           name: j?.name,
      //         }));
      //         cats?.unshift({ id: null, name: "" });
      //         console.log({ cats });
      //         return cats?.map((data) => {
      //           return { value: data.id, label: data.name };
      //         });
      //       })(),
      //       label: "cateogries.parent_cat",
      //     },
      //   ],
      //   buttons: [
      //     {
      //       text: "popups.add",
      //       closer: true,
      //       color: "success",
      //       handler: (obj) => {
      //         console.log({ parent_id: obj?.parent_id });
      //         if (obj?.parent_id == "null") delete obj.parent_id;
      //         console.log({ parent_id: obj?.parent_id });
      //         this.http.do("cateogries", obj).then((res) => {
      //           if (res.status) {
      //             if (this.$i18n.locale == "ar") {
      //               this.responseAlert(
      //                 "تم إضافة القسم بنجاح",
      //                 " تم ",
      //                 "success"
      //               );
      //             } else {
      //               this.responseAlert(
      //                 "Category added successfully",
      //                 "done",
      //                 "success"
      //               );
      //             }
      //           } else {
      //             if (this.$i18n.locale == "ar") {
      //               this.responseAlert(
      //                 "حدث خطأ اثناء إضافة القسم",
      //                 "خطأ",
      //                 "warning"
      //               );
      //             } else {
      //               this.responseAlert(
      //                 "something went wrong",
      //                 "error",
      //                 "warning"
      //               );
      //             }
      //           }
      //           this.get(this.page);
      //         });
      //       },
      //     },
      //     {
      //       text: "popups.cancel",
      //       closer: true,
      //       color: "danger",
      //       handler: () => {
      //         console.log("Like Clicked");
      //       },
      //     },
      //   ],
      // };
      // this.popup.modal(data);

      this.http.do("cateogries", this.category).then((res) => {
        if (res.status) {
          if (this.$i18n.locale == "ar") {
            this.responseAlert("تم إضافة القسم بنجاح", " تم ", "success");
          } else {
            this.responseAlert(
              "Category added successfully",
              "done",
              "success"
            );
          }
        } else {
          if (this.$i18n.locale == "ar") {
            this.responseAlert("حدث خطأ اثناء إضافة القسم", "خطأ", "warning");
          } else {
            this.responseAlert("something went wrong", "error", "warning");
          }
        }
        this.get(this.page);
      });
    },
    editCateogries() {
      console.log(this.selectedCategory);
      if (this.dropzoneFile) {
        this.selectedCategory.image = this.dropzoneFile;
        this.http
          .do("cateogries/update", {
            id: this.selectedCategory?.id,
            ...this.selectedCategory,
          })
          .then((res) => {
            if (res.status) {
              if (this.$i18n.locale == "ar") {
                this.responseAlert("تم تعديل القسم بنجاح", " تم ", "success");
              } else {
                this.responseAlert(
                  "Category hass been edited successfully",
                  "done",
                  "success"
                );
              }
            } else {
              if (this.$i18n.locale == "ar") {
                this.responseAlert(
                  "حدث خطأ اثناء تعديل القسم",
                  "خطأ",
                  "warning"
                );
              } else {
                this.responseAlert("something went wrong", "error", "warning");
              }
            }
            this.get(this.page);
          });
        return;
      }
      this.http
        .put("cateogries", this.selectedCategory?.id, this.selectedCategory)
        .then((res) => {
          if (res.status) {
            if (this.$i18n.locale == "ar") {
              this.responseAlert("تم تعديل القسم بنجاح", " تم ", "success");
            } else {
              this.responseAlert(
                "Category hass been edited successfully",
                "done",
                "success"
              );
            }
          } else {
            if (this.$i18n.locale == "ar") {
              this.responseAlert("حدث خطأ اثناء تعديل القسم", "خطأ", "warning");
            } else {
              this.responseAlert("something went wrong", "error", "warning");
            }
          }
          this.get(this.page);
        });
    },
    search() {
      if (this.searchModel == "") {
        this.cancelappsearchMode();
        return;
      }
      this.CateogriesSearchMode = true;
      this.tot_pages = 0;
      this.loading = true;
      this.http
        .post("cateogries/search", {
          search: this.searchModel,
          limit: this.limit,
          page: this.page,
          col: "name",
        })
        .then((res) => {
          if (res.data) {
            this.loading = false;
            this.Cateogries = res.data;
          }
          console.log("######## files", this.Cateogries);
        });
    },
    cancelappsearchMode() {
      this.searchModel = "";
      this.CateogriesSearchMode = false;
      this.get(this.page);
    },

    getCateogries() {
      this.http.get("cateogries").then((res) => {
        this.categories = res.data;
      });
    },
    deleteCateogries(app) {
      var data = {
        title: "popups.delete",
        msg: "popups.delmessage",
      };
      this.popup.confirm(data).then((resp) => {
        if (resp) {
          console.log("###");
          this.http.delete("cateogries", app.id).then((res) => {
            if (res.status) {
              if (this.$i18n.locale == "ar") {
                this.responseAlert("تم حذف القسم بنجاح", " تم ", "success");
              } else {
                this.responseAlert(
                  "Category hass been deleted successfully",
                  "done",
                  "success"
                );
              }
            } else {
              if (this.$i18n.locale == "ar") {
                this.responseAlert("حدث خطأ اثناء حذف القسم", "خطأ", "warning");
              } else {
                this.responseAlert("something went wrong", "error", "warning");
              }
            }
            console.log("############################3");
            this.get(this.page);
          });
        }
      });
    },

    get(page) {
      this.loading = true;
      console.log(page);
      this.http
        .post("cateogries/vendor/paginate", {
          limit: this.limit,
          page: page,
        })
        .then((res) => {
          if (res.status) {
            this.loading = false;
          }
          this.page = page;
          this.tot_pages = Math.ceil(res.tot / this.limit);
          this.Cateogries = res.data;
        });
    },
  },
  mounted() {
    this.get(1);
    this.getCateogries();
  },
};
</script>

<template>
  <Layout>
    <PageHeader
      :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
      :title="$t('menu.menuitems.categories.text')"
    />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-2">
              <div class="row">
                <div class="col-sm-4">
                  <div class="search-box me-2 mb-2 d-inline-block">
                    <div class="position-relative">
                      <input
                        @keyup="search()"
                        type="text"
                        class="form-control"
                        :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
                        :placeholder="$t('popups.search')"
                        v-model="searchModel"
                      />
                      <i class="bx bx-search-alt search-icon"></i>
                    </div>
                  </div>
                </div>
                <div
                  class="col-sm-8"
                  style="display: flex; justify-content: flex-end"
                >
                  <router-link to="add-category">
                    <button class="btn btn-success btn-rounded mb-2 me-2">
                      <i class="mdi mdi-plus me-1"></i>
                      {{ $t("cateogries.new.title") }}
                    </button>
                  </router-link>
                </div>
              </div>
              <div>
                <div class="table-responsive">
                  <table
                    :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
                    class="table table-centered table-nowrap table-striped table-hover align-middle"
                  >
                    <thead>
                      <tr
                        class="text-light"
                        style="background-color: #2a3042 !important"
                      >
                        <th scope="col">#</th>
                        <th scope="col">{{ $t("cateogries.name") }}</th>
                        <th scope="col">{{ $t("cateogries.image") }}</th>
                        <th scope="col">{{ $t("cateogries.created") }}</th>
                        <th scope="col">{{ $t("cateogries.updated") }}</th>
                        <th scope="col">{{ $t("cateogries.operations") }}</th>
                      </tr>
                    </thead>
                    <tbody v-if="!loading">
                      <tr
                        v-for="(app, index) in Cateogries"
                        :key="app"
                        class=""
                      >
                        <td class="p-top">{{ index + 1 }}</td>
                        <td class="p-top">
                          {{ $i18n.locale == "ar" ? app?.name_ar : app?.name }}
                        </td>
                        <td>
                          <img
                            v-if="app?.image"
                            :src="
                              $store.state?.auth?.mediaUrl +
                              app?.image?.split('public')[1]
                            "
                            style="width: 64px; height: 64px"
                            alt=""
                          />
                          <p v-else>-</p>
                        </td>
                        <td class="p-top">
                          {{ app?.created.split("T")[0] }}
                        </td>
                        <td class="p-top">
                          {{ app?.updated.split("T")[0] }}
                        </td>

                        <td class="d-flex p-top p-bottom">
                          <router-link
                            style="margin-inline-end: 8px"
                            :to="'/categories/products/' + app?.id"
                          >
                            <button
                              class="btn btn-info"
                              role="button"
                              data-toggle="tooltip"
                              data-placement="top"
                              :title="app?.name + ' ' + $t('products.profile')"
                            >
                              <i class="bx bxs-detail"></i>
                            </button>
                          </router-link>
                          <button
                            style="margin-inline-end: 8px"
                            class="btn btn-success"
                            @click="processEditCateogries(app)"
                            data-bs-toggle="modal"
                            data-bs-target="#editModal"
                          >
                            {{ $t("popups.edit") }}
                          </button>
                          <button
                            class="btn btn-danger"
                            @click="deleteCateogries(app)"
                          >
                            {{ $t("popups.delete") }}
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <Loader class="mt-4" v-if="loading" :loading="loading" />
                <!-- end  table -->
                <!-- Button trigger modal -->

                <div>
                  <!--   Apps  paginate     -->
                  <ul
                    class="pagination pagination-rounded justify-content-end mb-2"
                  >
                    <li class="page-item" :class="{ disabled: page == 1 }">
                      <a
                        class="page-link"
                        href="javascript: void(0);"
                        @click="get(page - 1)"
                        aria-label="Previous"
                      >
                        <i class="mdi mdi-chevron-left"></i>
                      </a>
                    </li>
                    <li
                      class="page-item"
                      :class="{ active: p == page }"
                      v-for="p in tot_pages"
                      :key="p"
                    >
                      <a
                        class="page-link"
                        href="javascript: void(0);"
                        @click="get(p)"
                        >{{ p }}</a
                      >
                    </li>

                    <li
                      class="page-item"
                      :class="{ disabled: page == tot_pages }"
                    >
                      <a
                        class="page-link"
                        href="javascript: void(0);"
                        @click="get(page + 1)"
                        aria-label="Next"
                      >
                        <i class="mdi mdi-chevron-right"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <!--end-->
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- start edit modal -->

    <div
      class="modal fade"
      id="editModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div>
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                {{ $t("popups.edit") }}
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                style="margin: 0"
              ></button>
            </div>

            <div class="modal-body" :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
              <div class="p-2">
                <div class="row d-flex align-items-center mb-2">
                  <label>{{ $t("cateogries.new.name") }}</label>
                  <input
                    type="text"
                    class="form-control"
                    name=""
                    v-model="selectedCategory.name"
                    id=""
                  />
                </div>
                <div class="row d-flex align-items-center mb-2">
                  <label>{{ $t("cateogries.new.name_ar") }}</label>
                  <input
                    type="text"
                    class="form-control"
                    name=""
                    v-model="selectedCategory.name_ar"
                    id=""
                  />
                </div>
                <div class="row d-flex align-items-center mb-2">
                  <label>{{ $t("products.image") }}</label>
                  <!-- <input
                  ref="myFile"
                  @change="selectedFile"
                  id="dropzone-file"
                  type="file"
                  name="photos"
                  class="form-control"
                /> -->
                  <DropZone @drop.prevent="drop" @change="selectedFile()" />
                </div>
                <div class="my-2 img-prev">
                  <img
                    style="width: 150px; height: 150px"
                    :src="
                      $store.state?.auth?.mediaUrl +
                      selectedCategory?.image?.split('public')[1]
                    "
                    id="prev-img-el"
                    alt=""
                  />
                </div>
              </div>
            </div>

            <div
              class="modal-footer"
              :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
            >
              <button
                data-bs-dismiss="modal"
                @click="editCateogries()"
                class="btn btn-success"
              >
                {{ $t("popups.edit") }}
              </button>
              <button
                data-bs-dismiss="modal"
                type="button"
                class="btn btn-secondary"
              >
                {{ $t("popups.cancel") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end edit modal -->
  </Layout>
</template>

<style scoped>
.btn-close {
  position: relative;
  /* right: 317px; */
  /* top: 6px; */
  font-size: 15px;
}
.p-top {
  padding-top: 2rem;
}
.p-bottom {
  padding-bottom: 1.5rem;
}
</style>
